<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="edit-in bg-white z-50 overflow-x-hidden lg:rounded-3xl shadow-2xl"
        v-if="showEditModal"
      >
        <div class="flex jce absolute top-0 right-0 w-100p">
          <button
            @click="$emit('close')"
            class="flex aic jce z-50 w-full px-8 py-6 text-primary h-80p"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div class="flex flex-col aic p-7 sm:p-10">
          <div class="flex items-end jcb w-full border-b mb-1 lg:mb-8 pb-2 lg:pb-4">
            <img
              :src="
                      product.image ? storageUrl + product.image : '/img/dish.webp'
                    "
              :alt="product.name"
              class="mod-edit-img img-shadow mt-4 lg:mt-0 mb-4 sm:mb-0"
            >
            <div class="flex flex-col aie jcc">
              <h2 class="text-gray-700 subway_footlong text-base sm:text-xl">{{product.name}}</h2>
              <span class="text-primary b text-lg whitespace-nowrap">{{product.price}} {{currency}}</span>
            </div>
          </div>
          <div class="flex flex-col ais jcc text-gray-700 text-sm md:text-base w-full">
            <div
              class="flex aic jcb w-full text-center text-sm edit-items"
              v-for="day in weekdaysList"
              :key="day.id"
            >
              <span
                class="text-sm lg:text-base b "
                :class="[qtyPerDay(day.id,product.pattern)?'text-secondary':'text-gray-500']"
              >{{$t(day.completeName)}}</span>
              <span
                class="text-green-500 text-center ml-auto mr-12"
                v-if="qtyPerDay(day.id,product.pattern)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 lg:h-6 lg:w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <div class="flex aic jcb space-x-1">
                <button
                  @click="remove(day.id)"
                  class="bg-gray-200 bg-opacity-40 p-2 rounded-lg lg:rounded-xl"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3 lg:h-5 lg:w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M18 12H6"
                    />
                  </svg>
                </button>
                <input
                  type="text"
                  name="qty"
                  readonly
                  class="w-30p text-center b text-base lg:text-xl  bg-transparent"
                  :class="[qtyPerDay(day.id,product.pattern)?'text-green-500':'text-gray-400']"
                  :value="qtyPerDay(day.id,product.pattern)"
                />
                <button
                  @click="add(day.id)"
                  class="bg-gray-200 bg-opacity-40 p-2 rounded-lg lg:rounded-xl"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3 lg:h-5 lg:w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <button
              type="button"
              @click="update"
              class="btn-secondary text-white w-full h-12 lg:h-16 rounded-xl text-base lg:text-xl b mt-4"
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        @click="$emit('close')"
        v-if="showEditModal"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["showEditModal", "selectedProduct", "patternId", "delete"],
  data() {
    return {
      product: null,
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
  },
  methods: {
    add(weekdayId) {
      var selectedDay = this.product.pattern.find(
        (item) => item.weekdayId === weekdayId
      );
      if (selectedDay) {
        this.product.pattern.find((item) => item.weekdayId === weekdayId)
          .quantity++;
      } else {
        this.product.pattern.push({
          weekdayId,
          quantity: 1,
        });
      }
    },
    remove(weekdayId) {
      var selectedDay = this.product.pattern.find(
        (item) => item.weekdayId === weekdayId
      );
      if (selectedDay) {
        if (selectedDay.quantity > 1) {
          this.product.pattern.find((item) => item.weekdayId === weekdayId)
            .quantity--;
        } else {
          this.product.pattern.splice(
            this.product.pattern.findIndex(
              (item) => item.weekdayId === weekdayId
            ),
            1
          );
        }
      } else return;
    },
    update() {
      if (this.product.pattern.length) {
        this.$store
          .dispatch("subscription/updatePattern", {
            patternId: this.patternId,
            productId: this.product.RestaurantProductId,
            pattern: this.product.pattern.map((m) => {
              return { weekday: m.weekdayId, quantity: m.quantity };
            }),
          })
          .then((res) => {
            if (res.type == "success") {
              this.$emit("close");
            } else
              this.$store.commit("setshowNotif", {
                show: true,
                msg: res.msg,
                type: res.type,
              });
          });
      } else {
        this.delete(this.patternId, this.product.RestaurantProductId).then(
          () => {
            this.$emit("close");
          }
        );
      }
    },
  },
  watch: {
    showEditModal(val) {
      if (val) this.product = { ...this.selectedProduct };
    },
  },
};
</script>
