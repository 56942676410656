<template>
  <div>
    <div class="hidden md:block">
      <transition
        enter-active-class="animate__animated animate__fadeIn animate__fast"
        leave-active-class="animate__animated animate__fadeOut animate__fast"
      >
        <div
          class="info-in bg-white z-hard overflow-x-hidden rounded-4xl shadow-2xl"
          v-if="showInfoModal"
        >
          <div class="flex jce absolute top-0 right-0 w-100p">
            <button
              @click="$emit('close')"
              class="flex aic jce z-hard w-full px-8 py-6 h-80p"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="flex flex-col aic p-7 sm:p-10">
            <div class="flex flex-col sm:flex-row items-center sm:items-end jcb w-full border-b mt-4 mb-8 pb-4">
              <h2 class="text-gray-900 text-sm sm:text-lg nunitoSans-bold capitalize">{{name}}</h2>
              <img
                :src="
                      image ? storageUrl + image : '/img/dish.webp'
                    "
                alt="#"
                class="mod-info-img img-shadow mb-4 sm:mb-0"
              >
            </div>
            <div class="flex flex-col ais jcc text-gray-700 text-sm md:text-base">
              {{description}}
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="md:hidden">
      <transition
        enter-active-class="animate__animated animate__fadeInUp animate__fast"
        leave-active-class="animate__animated animate__fadeOutDown animate__fast"
      >
        <div
          class="info-in bg-white z-hard overflow-x-hidden rounded-t-3xl md:rounded-3xl shadow-2xl"
          v-if="showInfoModal"
        >
          <div class="flex jce absolute top-0 right-0 w-100p">
            <button
              @click="$emit('close')"
              class="flex aic jce z-hard w-full px-8 py-6 h-80p"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="flex flex-col aic p-7 sm:p-10">
            <div class="flex flex-col sm:flex-row items-center sm:items-end jcb w-full border-b mt-4 mb-8 pb-4">
              <h2 class="text-gray-900 text-sm sm:text-lg capitalize nunitoSans-bold order-2 sm:order-1">{{name}}</h2>
              <img
                :src="
                      image ? storageUrl + image : '/img/dish.webp'
                    "
                alt="#"
                class="mod-info-img img-shadow mb-4 sm:mb-0 order-1 sm:order-2"
              >
            </div>
            <div class="flex flex-col ais jcc text-gray-700 text-sm md:text-base">
              {{description}}
            </div>
          </div>
        </div>
      </transition>
    </div>

    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        v-if="showInfoModal"
        @click="$emit('close')"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["showInfoModal", "image", "description", "name"],
  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
  },
};
</script>
