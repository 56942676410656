<template>
  <div class="flex aic jcb w-full">
    <div class="flex flex-col w-full">
      <div class="flex ais jcb prod-area pt-16 pb-32 lg:pb-20">
        <div class="flex flex-col w-full">
          <div class="flex dash-area ais jcb w-full">
            <div class="flex flex-col ais services">
              <div class="w-full flex panel-top aic jcb ">
                <div
                  class="flex flex-col ptop_left"
                  v-if="nextPattern && nextPattern.NextPatternProducts && nextPattern.NextPatternProducts.length"
                >
                  <span class="mb-6 nunitoSans-bold">Ihre nächste Lieferung</span>
                  <div class="flex aic jcb w-full nunitoSans-bold p-3 bg-gray-100 shadow-md text-sm">
                    <span class="text-secondary">{{$t(nextPattern.NextPatternDay)}}</span>
                    <span class="text-secondary">{{formatDate(nextPattern.NextPatternDate)}}</span>
                  </div>
                  <div class="flex flex-wrap justify-between sm:justify-start nachste-lieferung mt-4">
                    <div
                      class="flex flex-col space-y-1 aic justify-start space-x-2 w-2/5 sm:w-2/6 xl:w-2/6 lg:pb-4 px-2 py-2"
                      v-for="product in nextPattern.NextPatternProducts"
                      :key="product.PatternProductId"
                    >
                      <div class="flex aic jcc w-20 h-20 xl:w-20 xl:h-20">
                        <img
                          :src="
                      product.image ? storageUrl + product.image : '/img/dish.webp'
                    "
                          :alt="product.name"
                          class="w-full h-full img-shadow object-cover"
                        >
                      </div>
                      <div class="flex flex-col ais jcb">
                        <h2 class="text-xs md:text-sm text-secondary">{{product.name}}</h2>
                        <span class="text-sm text-gray-400"><b class="text-secondary mr-1">{{product.quantity}}</b> stk</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="flex flex-col ptop_left"
                >{{$t('no pattern has been recorded')}}</div>
                <div class="flex ptop_right aic jcc progress-area">
                  <div class="fncc">
                    <div class="progressdiv prog-month relative">
                      <div class="absolute top-0 left-0 fcc w-full h-full space-x-2">
                        <span class="text-4xl b text-green-400">{{userSammary}}</span>
                        <small class="text-gray-600">%</small>
                      </div>
                      <svg
                        class="progress"
                        width="178"
                        height="178"
                        viewport="0 0 100 100"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          r="80"
                          cx="89"
                          cy="89"
                          fill="transparent"
                          stroke-dasharray="502.4"
                          stroke-dashoffset="0"
                        ></circle>
                        <circle
                          class="bar"
                          r="80"
                          cx="89"
                          cy="89"
                          fill="transparent"
                          ref="userSammary"
                          stroke-dasharray="502.4"
                          stroke-dashoffset="0"
                        ></circle>
                      </svg>
                    </div>
                    <span class="text-gray-600 fs-14 mt-4">{{$t('weekly order percentage')}}</span>
                  </div>
                  <div class="fncc">
                    <div class="progressdiv prog-week relative">
                      <div class="absolute top-0 left-0 fcc w-full h-full space-x-2">
                        <span class="text-4xl b text-blue-400">{{userActivity}}</span>
                        <small class="text-gray-600">%</small>
                      </div>
                      <svg
                        class="progress"
                        width="178"
                        height="178"
                        viewport="0 0 100 100"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          r="80"
                          cx="89"
                          cy="89"
                          fill="transparent"
                          stroke-dasharray="502.4"
                          stroke-dashoffset="0"
                        ></circle>
                        <circle
                          class="bar"
                          r="80"
                          cx="89"
                          cy="89"
                          fill="transparent"
                          stroke-dasharray="502.4"
                          stroke-dashoffset="0"
                          ref="userActivity"
                        ></circle>
                      </svg>
                    </div>
                    <span class="text-gray-600 fs-14 mt-4">{{$t('user activity')}}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="flex flex-col w-full mt-12">
                <span class="mb-10 shadow-md p-3 font-medium bg-gray-100 bg-opacity-60">Ihre pläne</span>
                <div class="flex flex-wrap justify-start nachste-lieferung">
                  <div class="flex flex-col aic jcs liefer-in px-4 lg:px-8 py-6 md:pt-10 md:pb-8 bg-gray-50 shadow-xl rounded-6xl">
                    <div class="flex flex-col aic space-y-4 relative w-full">
                      <div class="flex aic jcb absolute right-0 top-0 lg:-top-3 space-x-3 w-full">
                        <span class="flex flex-col flex-1 ais">
                          <small class="text-gray-400 font-medium text-xs">Vom</small>
                          <b class="text-secondary font-bold text-xs">Aug.11.2021</b>
                        </span>
                        <button
                          @click="showEditModal = true"
                          class="bg-gray-100 shadow-lg betrieb rounded-lg text-secondary fcc"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 lg:h-5 lg:w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                      </div>
                      <div class="fcc w-full py-4">
                        <img
                          src="/img/products/brot7.webp"
                          alt="#"
                          class="plan-img img-shadow"
                        >
                      </div>
                      <div class="flex flex-col ais jcs w-full">
                        <h2 class="text-sm md:text-base font-medium text-gray-800">Kastenweißbrot</h2>
                        <span class="text-primary b text-base">€ 1.75</span>
                      </div>
                    </div>
                    <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:pt-4">
                      <div class="flex flex-col ais w-full text-sm pt-4 pb-4 lg:py-0 lg:pb-0">
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Montag</span>
                          <span class="text-gray-500 text-sm"><span class="text-secondary mr-3">3</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Dienstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">4</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Mittwoch</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">1</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Donnerstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">8</span>stk</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col aic jcs liefer-in px-4 lg:px-8 py-6 md:py-10 bg-gray-50 shadow-xl rounded-6xl">
                    <div class="flex flex-col aic space-y-4 relative w-full">
                      <div class="flex aic jcb absolute right-0 top-0 lg:-top-3 space-x-3 w-full">
                        <span class="flex flex-col flex-1 ais">
                          <small class="text-gray-400 font-medium text-xs">Vom</small>
                          <b class="text-secondary font-bold text-xs">Aug.11.2021</b>
                        </span>
                        <button
                          @click="showEditModal = true"
                          class="bg-gray-100 shadow-lg betrieb rounded-lg text-secondary fcc"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 lg:h-5 lg:w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                      </div>
                      <div class="fcc w-full">
                        <img
                          src="/img/products/brot2.webp"
                          alt="#"
                          class="plan-img img-shadow"
                        >
                      </div>
                      <div class="flex flex-col ais jcs w-full">
                        <h2 class="text-sm md:text-base font-medium text-gray-800">Dinkelywerg</h2>
                        <span class="text-primary b text-base">€ 2.35</span>
                      </div>
                    </div>
                    <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:pt-4">
                      <div class="flex flex-col ais w-full text-sm pt-4 pb-4 lg:py-0 lg:pb-0">
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Montag</span>
                          <span class="text-gray-500 text-sm"><span class="text-secondary mr-3">3</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Dienstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">4</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Samstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">3</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Sonntag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">5</span>stk</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-col aic jcs liefer-in px-4 lg:px-8 py-6 md:py-10 bg-gray-50 shadow-xl rounded-6xl">
                    <div class="flex flex-col aic space-y-4 relative w-full">
                      <div class="flex aic jcb absolute right-0 top-0 lg:-top-3 space-x-3 w-full">
                        <span class="flex flex-col flex-1 ais">
                          <small class="text-gray-400 font-medium text-xs">Vom</small>
                          <b class="text-secondary font-bold text-xs">Aug.11.2021</b>
                        </span>
                        <button
                          @click="showEditModal = true"
                          class="bg-gray-100 shadow-lg betrieb rounded-lg text-secondary fcc"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4 lg:h-5 lg:w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </button>
                      </div>
                      <div class="fcc w-full">
                        <img
                          src="/img/products/brot3.webp"
                          alt="#"
                          class="plan-img img-shadow"
                        >
                      </div>
                      <div class="flex flex-col ais jcs w-full">
                        <h2 class="text-sm md:text-base font-medium text-gray-800">Kraft Ballast Brot</h2>
                        <span class="text-primary b text-base">€ 3.15</span>
                      </div>
                    </div>
                    <div class="flex flex-col lg:flex-row items-end justify-between w-full lg:pt-4">
                      <div class="flex flex-col ais w-full text-sm pt-4 pb-4 lg:py-0 lg:pb-0">
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Mittwoch</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">1</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Donnerstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">8</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Freitag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">7</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Samstag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">3</span>stk</span>
                        </div>
                        <div class="flex aic justify-between w-full space-x-6">
                          <span class="text-secondary w-130p">Sonntag</span>
                          <span class="text-gray-500"><span class="text-secondary mr-3">5</span>stk</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div> -->
            </div>

            <div class="flex flex-col infos">
              <span class="mb-6 p-3 bg-gray-100 shadow-lg nunitoSans-bold text-secondary text-sm">{{$t('last order')}}</span>
              <div class="flex flex-col divide-y-2 divide-dashed divide-gray-100 ">
                <div
                  v-if="orderList"
                  class="max-h-60 overflow-y-auto"
                >
                  <div
                    class="flex aic jcb w-full px-2 py-3"
                    v-for="(item,i) in orderList.products"
                    :key="i"
                  >
                    <div class="flex flex-col space-y-2">
                      <div class="flex aic space-x-2">
                        <h2 class="text-sm text-gray-800 capitalize nunitoSans-bold">{{item.product_name}}</h2>
                        <span class="flex aic text-xs nunitoSans-bold text-gray-900">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-10p mr-1"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                          {{item.quantity}}
                        </span>
                        <!-- <button @click="showInfoModal = true" class="flex  aic jcc w-30p h-30p rounded-full text-primary fs-20"><i class="fa fa-info"></i></button> -->
                      </div>
                      <span class="text-xs">{{currency}} {{item.totalPrice}}</span>
                    </div>
                    <img
                      :src="
                item.product_image
                  ? storageUrl + item.product_image
                  : '/img/dish.webp'
              "
                      alt="#"
                      class="h-16 img-shadow"
                    >
                  </div>
                </div>

                <div class="flex flex-col aic jcb w-full space-y-4 my-12">
                  <div class="w-full flex flex-col infos">
                    <span class="mb-6 p-3 bg-gray-100 shadow-lg nunitoSans-bold text-secondary text-sm">{{$t('contact infromation')}}</span>
                    <div class="flex flex-col divide-y-2 divide-dashed divide-gray-100 space-y-2">
                      <div
                        class="flex aic space-x-3"
                        v-if="restaurantInfo.telephon"
                      >
                        <i
                          class="fa fa-phone text-secondary text-lg"
                          aria-hidden="true"
                        ></i>
                        <span class="text-sm">{{restaurantInfo.telephon}}</span>
                      </div>
                      <div
                        class="flex aic space-x-3"
                        v-if="restaurantInfo.email"
                      >
                        <i
                          class="fa fa-envelope text-secondary text-md"
                          aria-hidden="true"
                        ></i>
                        <span class="text-sm">{{restaurantInfo.email}}</span>
                      </div>
                      <div
                        class="flex aic space-x-3"
                        v-if="restaurantInfo.address"
                      >
                        <i
                          class="fa fa-map-marker text-secondary text-xl"
                          aria-hidden="true"
                        ></i>
                        <span class="text-xs">{{restaurantInfo.address}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <a
                    href="#"
                    class="shadow-xl w-full"
                  ><img
                      src="/img/ad-2.webp"
                      alt="#"
                      class="w-full h-40 object-cover"
                    ></a> -->
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <SideNav />
    <ProductInfo
      :showInfoModal="showInfoModal"
      @close="showInfoModal = false"
    ></ProductInfo>
    <ScheduleEdit
      :showEditModal="showEditModal"
      @close="showEditModal = false"
    ></ScheduleEdit>

  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
import ProductInfo from "../components/ProductInfo.vue";
import ScheduleEdit from "@/components/ScheduleEdit.vue";

export default {
  components: {
    SideNav,
    ProductInfo,
    ScheduleEdit,
  },
  data() {
    return {
      showInfoModal: false,
      showEditModal: false,
    };
  },
  computed: {
    nextPattern() {
      return this.$store.state.subscription.nextPattern;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    pattern() {
      return this.$store.state.subscription.pattern;
    },
    orderList() {
      return this.$store.state.orderiomApiPackage.order.orderList["order-list"]
        ? this.$store.state.orderiomApiPackage.order.orderList["order-list"][0]
        : [];
    },
    orderListPage() {
      return this.$store.state.orderiomApiPackage.order.orderList.paginate;
    },
    currentPage() {
      return this.$store.state.orderiomApiPackage.order.orderList.paginate
        .current_page;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
    userSammary() {
      return this.$store.state.subscription.userSammary;
    },
    userActivity() {
      return this.$store.state.subscription.userActivity;
    },
  },
  watch: {
    userSammary(val) {
      if (val) {
        var totalProgress;
        const circles = document.querySelectorAll(".progress");
        for (var i = 0; i < circles.length; i++) {
          totalProgress = circles[i]
            .querySelector("circle")
            .getAttribute("stroke-dasharray");
          this.$refs.userSammary.style["stroke-dashoffset"] =
            (totalProgress * val) / 100;
        }
      }
    },
    userActivity(val) {
      if (val) {
        var totalProgress;
        const circles = document.querySelectorAll(".progress");
        for (var i = 0; i < circles.length; i++) {
          totalProgress = circles[i]
            .querySelector("circle")
            .getAttribute("stroke-dasharray");
          this.$refs.userActivity.style["stroke-dashoffset"] =
            (totalProgress * val) / 100;
        }
      }
    },
  },
  beforeMount() {
    this.$store.commit("subscription/setUserSummary", null);
    this.$store.commit("subscription/setUserActivity", null);
  },
  created() {
    this.$store.dispatch("subscription/getNextPattern");
    this.$store.dispatch("subscription/getPattern");
    this.$store.dispatch("subscription/getUserSummary");
    this.$store.dispatch("subscription/getUserActivity");
    this.$store.dispatch("order/getOrderHistory");
  },
};
</script>

<style></style>
