<template>
  <div>
    <div class="side-menu hidden md:flex flex-col shadow-xl">
      <div class="flex-1 flex flex-col pb-4 overflow-y-auto side-menu-in">
        <nav
          class="flex-1 side-nav"
          aria-label="Sidebar"
        >
          <div class="flex aic jcc w-full h-70p space-x-4 px-6 nav-top bg-black">
            <h2 class="b text-white">DASHBOARD</h2>
          </div>
          <router-link
            exact-active-class="exact-active"
            to="/dashboard"
            v-wave
            class="sn-a flex aic px-6 py-4 text-sm relative cursor-pointer w-full play-bold exact-menu"
          >
            <div class="flex aic w-full z-hard relative text-left">
              <div class="side-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 opacity-40"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 7.093v-5.093h-3v2.093l3 3zm4 5.907l-12-12-12 12h3v10h7v-5h4v5h7v-10h3zm-5 8h-3v-5h-8v5h-3v-10.26l7-6.912 7 6.99v10.182z" />
                </svg>
              </div>
              Startseite
            </div>
          </router-link>
          <router-link
            exact-active-class="exact-active"
            to="/dashboard/anlegen"
            v-wave
            class="sn-a flex aic px-6 py-4 text-sm relative cursor-pointer w-full play-bold exact-menu"
          >
            <div class="flex aic w-full z-hard relative text-left">
              <div class="side-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 opacity-40 mirror"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 3l-.743 2h-1.929l-3.474 12h-13.239l-4.615-11h16.812l-.564 2h-13.24l2.937 7h10.428l3.432-12h4.195zm-15.5 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.9-7-1.9 7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z" />
                </svg>
              </div>
              Anlegen
            </div>
          </router-link>
          <router-link
            exact-active-class="exact-active"
            to="/dashboard/ansehen-anderen"
            v-wave
            class="sn-a flex aic px-6 py-4 text-sm relative cursor-pointer w-full play-bold exact-menu"
          >
            <div class="flex aic w-full z-hard relative text-left">
              <div class="side-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 opacity-40"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
              </div>
              Ansehen / Anderen
            </div>
          </router-link>
          <router-link
            exact-active-class="exact-active"
            to="/dashboard/zusatzbestellung"
            v-wave
            class="sn-a flex aic px-6 py-4 text-sm relative cursor-pointer w-full play-bold exact-menu"
          >
            <div class="flex aic w-full z-hard relative text-left">
              <div class="side-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 opacity-40 mirror"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.5 18c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm-3.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5zm14-16.5l-.743 2h-1.929l-3.473 12h-13.239l-4.616-11h2.169l3.776 9h10.428l3.432-12h4.195zm-12 4h3v2h-3v3h-2v-3h-3v-2h3v-3h2v3z" />
                </svg>
              </div>
              Zusatzbestellung
            </div>
          </router-link>
          <router-link
            exact-active-class="exact-active"
            to="/dashboard/unterbrechen"
            v-wave
            class="sn-a flex aic px-6 py-4 text-sm relative cursor-pointer w-full play-bold exact-menu"
          >
            <div class="flex aic w-full z-hard relative text-left">
              <div class="side-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 opacity-40"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              Unterbrechen
            </div>
          </router-link>
        </nav>
      </div>
      <div class="flex flex-col border-t side-nav-bottom">
        <div class="sn-a flex aic px-9 py-4 text-sm font-medium relative">
          <div class="flex aic w-full z-hard">
            <p class="fs-11 text-gray-600 w-full">
              Copyright © 2021
              <span class="text-black">Brotchen Shop</span>
              {{$t("Created by")}} <a
                href="https://www.orderiom.de/"
                target="_blank"
                rel="noopener"
              ><strong>Orderiom</strong></a>
            </p>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    logo() {
      return process.env.VUE_APP_LOGO;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    categories() {
      if (this.$store.state.orderiomApiPackage.product.categories.length) {
        const priorityList =
          this.$store.state.orderiomApiPackage.product.categories.map(
            (m) => m.pivot.priority
          );
        if (priorityList.every((item) => item != null)) {
          const categories = [
            ...this.$store.state.orderiomApiPackage.product.categories,
          ].sort((a, b) => (a.pivot.priority > b.pivot.priority ? 1 : -1));
          return categories;
        } else return this.$store.state.orderiomApiPackage.product.categories;
      } else return this.$store.state.orderiomApiPackage.product.categories;
    },
    selectedCategory() {
      return this.$store.state.orderiomApiPackage.product.selectedCategory;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    restaurantInfo() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo;
    },
  },
  methods: {
    selectCategory(category) {
      this.$store.commit("product/setSelectedCategory", category);
      this.$store.dispatch("product/getProducts", {
        category: category,
        restaurantId: this.restaurantId,
      });
      this.$store.dispatch("product/getAttributeItems", {
        category: category,
        restaurantId: this.restaurantId,
      });
    },
  },
};
</script>
